// import { isDemo } from '@/auth/utils'

export default [
  {
    title: 'Dashboards',
    icon: 'fa-solid fa-gauge-high',
    children: [
      {
        title: 'Analítico',
        route: { name: 'dashboard-analitico' },
        resource: 'dashboard_analitico',
        action: 'read',
      },
    ],
  },
  {
    title: 'Federal',
    icon: 'fa-solid fa-earth-americas',
    children: [
      {
        title: 'Situação Fiscal',
        route: { name: 'situacaofiscal-view' },
        resource: 'situacaofiscal_federal',
        action: 'read',
      },
      {
        title: 'DCTFWeb',
        resource: 'declaracao_dctfweb',
        route: { name: 'dctfweb-view' },
        action: 'read',
        // visible: () => isDemo(),
      },
      {
        title: 'Comprovante Arrecadação',
        resource: 'comprovante_pagamento',
        route: { name: 'comprovante-view' },
        action: 'read',
        dataLancado: '2023-12-15',
      },
    ],
  },
  {
    title: 'Certidões',
    icon: 'fa-solid fa-stamp',
    children: [
      {
        title: 'RFB/PGFN',
        route: { name: 'cndfederal-view' },
        resource: 'cnd_federal',
        action: 'read',
      },
      {
        title: 'FGTS',
        route: { name: 'cndfgts-view' },
        resource: 'cnd_fgts', //
        action: 'read',
      },
      {
        title: 'Trabalhista',
        route: { name: 'cndtrabalhista-view' },
        resource: 'cnd_trabalhista',
        action: 'read',
      },
      // {
      //   title: 'MTE',
      //   route: { name: 'cndmte-view' },
      //   resource: 'cnd_mte',
      //   action: 'read',
      //   dataLancado: '2023-07-07',
      // },
      {
        title: 'Estadual',
        route: { name: 'cndestadual-view' },
        resource: 'cnd_estadual',
        action: 'read',
      },
      {
        title: 'Municipal',
        route: { name: 'cndmunicipal-view' },
        resource: 'cnd_municipal',
        action: 'read',
      },
    ],
  },
  {
    title: 'Caixa Postal',
    icon: 'fa-solid fa-envelope',
    children: [
      {
        title: 'Mensagens e-CAC',
        route: { name: 'caixapostal-view' },
        resource: 'caixapostal_ecac',
        action: 'read',
      },
      // {
      //   title: 'Estadual',
      //   resource: 'caixapostal_estadual',
      //   route: { name: 'caixapostal_estadual-view' },
      //   action: 'read',
      //   dataLancado: '2023-09-25',
      // },
    ],
  },
  {
    title: 'Parcelamentos',
    icon: 'fa-solid fa-money-bills',
    children: [
      {
        title: 'Simples Nacional',
        resource: 'das_parcelamento',
        route: { name: 'dasparcelamento-view', query: { filtroRapido: 'EmParcelamento' } },
        action: 'read',
      },
      // {
      //   title: 'LEI-12.996',
      //   resource: 'parcelamento_lei',
      //   action: 'read',
      //   tag: 'Em breve',
      //   tagVariant: 'light-primary',
      //   disabled: true,
      // },
      {
        title: 'PERT-SN',
        resource: 'parcelamento_pertsn',
        action: 'read',
        route: { name: 'parcelamento_pertsn-view', query: { filtroRapido: 'EmParcelamento' } },
        tagVariant: 'light-primary',
      },
      // {
      //   title: 'MEI',
      //   resource: 'parcelamento_mei',
      //   action: 'read',
      //   tag: 'Em breve',
      //   tagVariant: 'light-primary',
      //   disabled: true,
      // },
      {
        title: 'RELP-SN',
        resource: 'parcelamento_relpsn',
        route: { name: 'parcelamento_relpsn-view', query: { filtroRapido: 'EmParcelamento' } },
        action: 'read',
      },
      {
        title: 'RFB',
        resource: 'parcelamento_rfb',
        action: 'read',
        route: { name: 'parcelamento_rfb-view', query: { filtroRapido: 'EmParcelamento' } },
        tagVariant: 'light-primary',
      },
      {
        title: 'Não Previdenciário',
        resource: 'parcelamento_naoprevidenciario',
        action: 'read',
        route: { name: 'parcelamento_nao_previdenciario-view', query: { filtroRapido: 'EmParcelamentoAguardando' } },
        tagVariant: 'light-primary',
      },
      {
        title: 'Simplificado Previdenciário',
        resource: 'parcelamento_simp_previdenciario',
        action: 'read',
        route: { name: 'parcelamento_simpl_previdenciario-view', query: { filtroRapido: 'Ativos' } },
        dataLancado: '2023-11-20',
      },
      // {
      //   title: 'RELP-MEI',
      //   resource: 'parcelamento_relpmei',
      //   action: 'read',
      //   tag: 'Em breve',
      //   tagVariant: 'light-primary',
      //   disabled: true,
      // },
    ],
  },
  {
    title: 'Simples Nacional',
    icon: 'fa-solid fa-s',
    children: [
      {
        title: 'Sublimite',
        resource: 'sublimite',
        route: { name: 'sublimite-view' },
        action: 'read',
        dataLancado: '2023-07-07',
      },
      {
        title: 'DAS',
        resource: 'das',
        route: { name: 'das-view' },
        action: 'read',
        dataLancado: '2023-09-08',
      },
    ],
  },
  {
    title: 'PGFN',
    icon: 'fa-sharp fa-scale-balanced',
    children: [
      {
        title: 'Parcelamento',
        resource: 'pgfn_parcelamento',
        route: { name: 'pgfnparcelamento-view', query: { filtroRapido: 'ComParcelamento' } },
        action: 'read',
        dataLancado: '2023-07-17',
      },
    ],
  },
  {
    title: 'Documentos',
    icon: 'fa-sharp fa-regular fa-file-lines',
    // visible: () => isDemo(),
    children: [
      {
        title: 'Portal',
        resource: 'portal_documentos',
        route: { name: 'portal-documento-view' },
        action: 'read',
      },
      {
        title: 'CNDs',
        resource: 'cnd_portal_documentos',
        route: { name: 'cnd-portal-documento-view' },
        action: 'read',
      },
    ],
  },
  // {
  //   title: 'Simples Nacional / MEI',
  //   icon: 'fa-solid fa-file-invoice',
  //   children: [
  //     {
  //       title: 'PGDAS - Declarações',
  //       resource: 'pgdas_declaracoesa',
  //       action: 'read',
  //       tag: 'Em breve',
  //       tagVariant: 'light-primary',
  //       disabled: true,
  //     },
  //     {
  //       title: 'PGDAS - Pendências',
  //       resource: 'pgdas_pendencias',
  //       action: 'read',
  //       tag: 'Em breve',
  //       tagVariant: 'light-primary',
  //       disabled: true,
  //     },
  //     {
  //       title: 'PGDAS - Geração DAS',
  //       resource: 'pgdas_geracaodas',
  //       action: 'read',
  //       tag: 'Em breve',
  //       tagVariant: 'light-primary',
  //       disabled: true,
  //     },
  //     {
  //       title: 'Sublimite',
  //       resource: 'sublimite',
  //       action: 'read',
  //       tag: 'Em breve',
  //       tagVariant: 'light-primary',
  //       disabled: true,
  //     },
  //     {
  //       title: 'DEFIS',
  //       resource: 'defis',
  //       action: 'read',
  //       tag: 'Em breve',
  //       tagVariant: 'light-primary',
  //       disabled: true,
  //     },
  //     {
  //       title: 'Exclusão do Simples',
  //       resource: 'exclusao_simples',
  //       action: 'read',
  //       tag: 'Em breve',
  //       tagVariant: 'light-primary',
  //       disabled: true,
  //     },
  //     {
  //       title: 'Exclusão do MEI',
  //       resource: 'exclusao_mei',
  //       action: 'read',
  //       tag: 'Em breve',
  //       tagVariant: 'light-primary',
  //       disabled: true,
  //     },
  //   ],
  // },
  {
    title: 'Cadastros',
    icon: 'fa-solid fa-table-list',
    children: [
      {
        title: 'Empresas',
        route: { name: 'empresa-view' },
        resource: 'empresa',
        action: 'read',
      },
      {
        title: 'Usuários',
        route: { name: 'usuario-view' },
        resource: 'usuario',
        action: 'read',
      },
      {
        title: 'Certificados',
        route: { name: 'certificado-view' },
        resource: 'certificadodigital',
        action: 'read',
      },
    ],
  },
  {
    title: 'Configurações',
    icon: 'fa-solid fa-gear',
    children: [
      {
        title: 'SMTP',
        route: { name: 'email-config' },
        resource: 'emailconfig',
        action: 'read',
      },
      {
        title: 'Perfil da empresa',
        route: { name: 'perfil-empresa' },
        resource: 'perfil_empresa',
        action: 'read',
      },
      {
        title: 'Parâmetros do sistema',
        route: { name: 'parametros-sistema' },
        resource: 'parametro_sistema',
        action: 'read',
      },
    ],
  },
  {
    title: 'Loja virtual',
    icon: 'fa-solid fa-bag-shopping',
    children: [
      {
        title: 'Plano',
        route: { name: 'plano-view' },
        resource: 'plano',
        action: 'read',
        dataLancado: '2024-08-29',
      },
      {
        title: 'Aquisição de créditos',
        route: { name: 'aquisicao-credito' },
        resource: 'aquisicao_credito',
        action: 'read',
        dataLancado: '2024-09-12',
      },
      {
        title: 'Relatório de consumo',
        route: { name: 'relatorio-consumo' },
        resource: 'relatorio_consumo',
        action: 'read',
      },
    ],
  },
]
